import { useOidcAccessToken } from "@axa-fr/react-oidc";
import axios, { AxiosResponse } from "axios";

interface ApiClientParams {
  [key: string]: any;
}

interface ApiClientObjectQueryParams {
  [key: string]:
    | string
    | number
    | boolean
    | undefined
    | (string | number | boolean)[];
}

type ApiClientQueryParams = ApiClientObjectQueryParams | string;

interface ApiClientConfig {
  isAuthenticated?: boolean;
  headers?: { [key: string]: string };
}

async function constructHeadersFromApiClientConfig(config?: ApiClientConfig) {
  return config?.headers;
}

function constructQueryParams(params?: ApiClientQueryParams) {
  if (!params) {
    return "";
  }

  let queryParams = "";

  if (typeof params === "object") {
    Object.keys(params)
      .filter((key) => params[key] !== undefined)
      .forEach((key, index) => {
        const value = params[key];
        if (value !== undefined) {
          if (index === 0) {
            queryParams += "?";
          } else {
            queryParams += "&";
          }

          // option 2
          let queryValue = "";
          if (Array.isArray(value)) {
            queryValue = value.reduce<string>((result, _value, index) => {
              let text = "";
              if (index > 0) {
                text += "&";
              }
              text += `${key}=${encodeURIComponent(_value)}`;

              return result + text;
            }, "");
          } else {
            queryValue = `${key}=${encodeURIComponent(value)}`;
          }

          queryParams += queryValue;
        }
      });
  } else if (typeof params === "string") {
    queryParams += "?" + params;
  }

  return queryParams;
}

function extractSuccessResponse<T>(promiseFn: Promise<AxiosResponse<T>>) {
  return promiseFn.then((response) => response.data);
}

async function get<T>(
  url: string,
  params?: ApiClientQueryParams,
  config?: ApiClientConfig
) {
  return extractSuccessResponse<T>(
    axios.get<T>(url + constructQueryParams(params), {
      headers: await constructHeadersFromApiClientConfig(config),
    })
  );
}

async function post<T>(
  url: string,
  params?: ApiClientParams,
  config?: ApiClientConfig
) {
  return extractSuccessResponse<T>(
    axios.post<T>(url, params, {
      headers: await constructHeadersFromApiClientConfig(config),
    })
  );
}

async function put<T>(
  url: string,
  params?: ApiClientParams,
  config?: ApiClientConfig
) {
  return extractSuccessResponse<T>(
    axios.put<T>(url, params, {
      headers: await constructHeadersFromApiClientConfig(config),
    })
  );
}

async function patch<T>(
  url: string,
  params?: ApiClientParams,
  config?: ApiClientConfig
) {
  return extractSuccessResponse<T>(
    axios.patch<T>(url, params, {
      headers: await constructHeadersFromApiClientConfig(config),
    })
  );
}

async function deleteFunc<T>(
  url: string,
  params?: ApiClientObjectQueryParams,
  config?: ApiClientConfig
) {
  return extractSuccessResponse<T>(
    axios.delete<T>(url, {
      headers: await constructHeadersFromApiClientConfig(config),
      data: params,
    })
  );
}

const apiClient = {
  get,
  post,
  patch,
  put,
  delete: deleteFunc,
};

export default apiClient;
