import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc";
import config from "config";
import Documentation from "pages/Documentation";
import { Link, Redirect, Route, Switch } from "wouter";
import {
  Button,
  ButtonGroup,
  ChakraProvider,
} from "@chakra-ui/react";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";

Amplify.configure({
  // node variable name must always has prefix of `REACT_APP` or else it won't be loaded by react-scripts
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
      scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
      redirectSignIn: config.redirectUrl,
      redirectSignOut: config.logoutUrl,
      responseType: 'code',
    },
  },
})

function App() {
  return (
    <>
      <ChakraProvider>
        <ButtonGroup>
          <Button colorScheme="teal" variant="ghost">
            <Link href="/customer">Customer Apidoc</Link>
          </Button>
          <Button colorScheme="teal" variant="ghost">
            <Link href="/tool">Tool Apidoc</Link>
          </Button>
          <Button colorScheme="teal" variant="ghost">
            <Link href="/wms">WMS App Apidoc</Link>
          </Button>
        </ButtonGroup>
      </ChakraProvider>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          top: "40px",
          left: 0,
          right: 0,
        }}
      >
        <Switch>
          <Route path="/customer">
            {() => <Documentation selectedAPI={"customer"} />}
          </Route>
          <Route path="/tool">
            {() => <Documentation selectedAPI={"tool"} />}
          </Route>
          <Route path="/wms">
            {() => <Documentation selectedAPI={"wms"} />}
          </Route>
          <Route path="/:rest*">
            <Redirect to="/customer" />
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default withAuthenticator(App, {
  socialProviders: ['google'],
  hideSignUp: true,
});
