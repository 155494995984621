import { useEffect, useRef } from "react";
import withPermission from "utils/withPermission";
import "rapidoc";
import apiClient from "utils/api-client";
import config from "config";
import { Auth } from "aws-amplify";

function Documentation({ selectedAPI }) {
  const docRef = useRef(null);
  useEffect(() => {
    async function fetchData() {
      const currentSession = await Auth.currentSession();
      const accessToken = currentSession.getAccessToken().getJwtToken();

      if (!accessToken) return;

      const result = await apiClient.get(
        config.apiHost + `/documentations/${selectedAPI}`,
        null,
        {
          isAuthenticated: true,
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const documentations = document.getElementById("documentations");
      documentations.loadSpec(result);
    }

    fetchData();
  }, [selectedAPI]);

  return (
    <>
      <rapi-doc
        id="documentations"
        ref={docRef}
        show-header={false}
        allow-spec-url-load={false}
        allow-spec-file-load={false}
        allow-spec-file-download={true}
        update-route={true}
      />
    </>
  );
}

export default withPermission(Documentation, ["data-manager"]);
