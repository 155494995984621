import { Container, Flex, Heading, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { hasPermission, PermissionValidationSchema } from "./has-permission";
import { Auth } from "aws-amplify";
export default function withPermission<T>(
  Component: React.ComponentType<T>,
  permissions: string[],
  options?: {
    title?: string;
    validationSchema?: PermissionValidationSchema;
    hideIfNotValid?: boolean;
  }
) {
  return function (props: T) {
    const {
      title = "",
      validationSchema = "contain-one",
      hideIfNotValid,
    } = options || {};

    const [isValid, setValid] = useState(false);

    const handleCheckPermission = useCallback(async (permissions: string[], validationSchema: PermissionValidationSchema) => {
      const session = await Auth.currentSession();
      setValid(
        hasPermission(
          session.getIdToken().payload["cognito:groups"],
          permissions,
          validationSchema
        )
      );
    }, [setValid])

    useEffect(() => {
      handleCheckPermission(permissions, validationSchema)
    }, []);

    if (!isValid) {
      if (hideIfNotValid) {
        return null;
      }

      return (
        <Container display={"flex"} justifyContent={"center"}>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            p={"16px"}
            flexDirection={"column"}
            width={"500px"}
            border={"1px solid #dddddd"}
            mt={"32px"}
          >
            <Heading fontSize={"xl"} paddingBottom={"3"}>
              {title}
            </Heading>
            <Text
              mb={"16px"}
            >{`You don't have permission to access this section`}</Text>
          </Flex>
        </Container>
      );
    }

    return <Component {...props} />;
  };
}
