export type PermissionValidationSchema = "match-all" | "contain-one";
export function hasPermission(
  userPermission: string[],
  requiredPermission: string[],
  validationSchema: PermissionValidationSchema = "match-all"
): boolean {
  const userPermissionSet: Set<string> = new Set(userPermission);

  let isPageAccessible: boolean = false;

  if (requiredPermission.length > 0) {
    for (let permission of requiredPermission) {
      const matchPermission = userPermissionSet.has(permission);

      if (matchPermission) {
        isPageAccessible = true;
      } else if (!matchPermission && validationSchema === "match-all") {
        isPageAccessible = false;
        break;
      }
    }
  } else {
    // if the length of permission is zero, then set isValid to true
    isPageAccessible = true;
  }

  return isPageAccessible;
}
