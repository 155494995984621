import defaultConfig from "./default.json";
import developmentConfig from "./development.json";
import productionConfig from "./production.json";

interface Config {
  apiHost: string;
  redirectUrl: string;
  logoutUrl: string;
}

const config: Config = {
  // add REACT_APP_OVERRIDE_APP_URL to override url for custom domain
  redirectUrl: `https://${
    process.env.REACT_APP_OVERRIDE_APP_URL || process.env.REACT_APP_VERCEL_URL
  }/callback`,
  logoutUrl: `https://${
    process.env.REACT_APP_OVERRIDE_APP_URL || process.env.REACT_APP_VERCEL_URL
  }/logout`,
  ...defaultConfig,
  // NB: update this to other if not using vercel anymore
  ...(process.env.REACT_APP_ENV === "development" ||
  process.env.REACT_APP_ENV === undefined
    ? developmentConfig
    : {}),
  // Special cases for Cloudflare pages
  ...(process.env.REACT_APP_CF_PAGES === "1"
    ? {
        redirectUrl: `${process.env.REACT_APP_CF_PAGES_URL}/callback`,
        logoutUrl: `${process.env.REACT_APP_CF_PAGES_URL}/logout`,
      }
    : {}),
  ...(process.env.REACT_APP_CF_PAGES === "1" &&
  (process.env.REACT_APP_CF_PAGES_BRANCH === "main" ||
    process.env.REACT_APP_CF_PAGES_BRANCH === "release")
    ? {
        redirectUrl: `https://${process.env.REACT_APP_OVERRIDE_APP_URL}/callback`,
        logoutUrl: `https://${process.env.REACT_APP_OVERRIDE_APP_URL}/logout`,
      }
    : {}),
  // end of Cloudflare special case
  ...(process.env.REACT_APP_ENV === "production"
    ? productionConfig
    : {}),
};

export default config;
